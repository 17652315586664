<template>
  <b-taginput
    keep-first
    open-on-focus
    clearable
    autocomplete
    v-model="val"
    field="name"
    maxtags="1"
    :allow-new="false"
    :data="options"
    @input="setInput"
    :id="id"
    :disabled="disabled"
    :closable="!disabled"
    aria-label="role-type-selector"
  >
  </b-taginput>
</template>

<script>
import { findRolesTypes } from '@/requests/memberships'

export default {
  name: 'RoleTypeSelector',
  data() {
    return {
      options: [],
      val: []
    }
  },
  props: {
    value: {
      default: null,
      validator: (prop) => typeof prop === 'number' || prop === null,
      required: false
    },
    id: { required: false, type: String },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    setInput(input) {
      if (input.length === 1) {
        this.$emit('input', input[0].id)
      } else {
        this.$emit('input', null)
      }
    }
  },
  async mounted() {
    this.options = await findRolesTypes()
    this.val = this.options.filter((option) => {
      return option.id === this.value
    })
  }
}
</script>
