<template>
  <div class="container mt-4">
    <h1 class="title is-4">
      {{ $t('views.editMembership.title') }}
    </h1>
    <v-promised :promise="promises">
      <template #pending>
        <b-loading :can-cancel="false"></b-loading>
      </template>

      <template v-slot="[division, membership]">
        <div class="columns">
          <div class="column is-7">
            <div class="card">
              <v-form
                :on-submit="editMembership"
                :initial-value="{
                  role_type_id: membership.role_type_id,
                  person_id: membership.id,
                  division: division.id
                }"
                :onSubmitSuccess="successEditedMembership"
                :zodSchemaValidator="zodSchemaValidator"
              >
                <template v-slot="{ form }">
                  <div class="card-content">
                    <div class="columns">
                      <div class="column">
                        <b-field :label="$t('views.editMembership.email')">
                          {{ membership.email }}
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field
                          :label="$t('views.editMembership.rol')"
                          id="membership_type-select"
                          :message="form.errors.role_type_id"
                          :type="form.types.role_type_id"
                          label-for="membership_type-select-input"
                          data-testid="membership_type-select-input"
                        >
                          <role-type-selector
                            v-model="form.values.role_type_id"
                            id="membership_type-select-input"
                          />
                        </b-field>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column">
                        <b-field
                          :label="
                            division.is_project
                              ? $t('views.editMembership.project')
                              : $t('views.editMembership.division')
                          "
                        >
                          {{ division.name }}
                        </b-field>
                      </div>
                    </div>
                    <div class="buttons is-right">
                      <b-button class="is-azul-imfd-invert" @click="$router.go(-1)">
                        {{ $t('views.editMembership.cancel') }}
                      </b-button>

                      <b-button
                        icon-left="pencil"
                        native-type="submit"
                        class="is-azul-imfd"
                        :disabled="!form.errors"
                      >
                        {{ $t('views.editMembership.submit') }}
                      </b-button>
                    </div>
                  </div>
                </template>
              </v-form>
            </div>
          </div>

          <div class="column is-5">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                  </div>

                  <div class="media-content">
                    <p>
                      {{ $t('views.editMembership.warningLabel') }}
                      <strong>{{ division.name }}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rejected="error">
        <v-fail :error="error" />
      </template>
    </v-promised>
  </div>
</template>

<script>
import VForm from '@/components/VForm.vue'
import { getMemberRole, findDivision, deleteMemberRole } from '@/requests/divisions'
import { addMemberToDivision } from '@/requests/memberships'
import RoleTypeSelector from '@/components/RoleTypeSelector'
import { z } from 'zod'

export default {
  components: {
    RoleTypeSelector,
    VForm
  },
  data() {
    return {
      promises: null,
      zodSchemaValidator: z.object({
        person_id: z.number(),
        division: z.number(),
        role_type_id: z.number()
      })
    }
  },
  async mounted() {
    this.promises = Promise.all([
      findDivision({ divisionId: this.$route.params.divisionId }),
      getMemberRole({
        divisionId: this.$route.params.divisionId,
        memberId: this.$route.params.memberId
      })
    ])
  },
  methods: {
    async editMembership(form) {
      await deleteMemberRole({
        divisionId: this.$route.params.divisionId,
        memberId: this.$route.params.memberId
      })
      return await addMemberToDivision(form)
    },
    successEditedMembership() {
      this.$buefy.toast.open({
        message: this.$t('views.editMembership.successMessage'),
        type: 'is-success',
        position: 'is-top'
      })
      this.$router.push({
        name: 'division',
        params: { id: this.$route.params.divisionId },
        query: { tab: 'members' }
      })
    }
  }
}
</script>

<style scoped>
.final-button {
  margin: 1em;
}
</style>
