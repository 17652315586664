import axios from './index'
import FormValidationError from '@/errors/FormValidationError'
import NotAllowedError from '@/errors/NotAllowedError'

export async function findMembershipsTypes() {
  const response = await axios.get('api/memberships/membership-types')

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Fail to fetch memberships types')
  }
}

export const addMemberToDivision = async (data) => {
  const response = await axios.post(`api/divisions/${data.division}/members`, data)

  if (response.status === 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 403) {
    throw new NotAllowedError(response.data)
  }
  throw new Error('Unexpected status code returned')
}

export const createMembership = async (data) => {
  const response = await axios.post(`api/divisions/${data.division}/invitations`, data)

  if (response.status === 201) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 403) {
    throw new NotAllowedError(response.data)
  }

  throw new Error('Unexpected status code returned')
}

export async function findRolesTypes() {
  const response = await axios.get('api/memberships/roles-types')

  if (response.status === 200) {
    return response.data
  } else {
    throw new Error('Fail to fetch memberships types')
  }
}
