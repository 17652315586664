var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mt-4"},[_c('h1',{staticClass:"title is-4"},[_vm._v(" "+_vm._s(_vm.$t('views.editMembership.title'))+" ")]),_c('v-promised',{attrs:{"promise":_vm.promises},scopedSlots:_vm._u([{key:"pending",fn:function(){return [_c('b-loading',{attrs:{"can-cancel":false}})]},proxy:true},{key:"default",fn:function(ref){
var division = ref[0];
var membership = ref[1];
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-7"},[_c('div',{staticClass:"card"},[_c('v-form',{attrs:{"on-submit":_vm.editMembership,"initial-value":{
                role_type_id: membership.role_type_id,
                person_id: membership.id,
                division: division.id
              },"onSubmitSuccess":_vm.successEditedMembership,"zodSchemaValidator":_vm.zodSchemaValidator},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var form = ref.form;
return [_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('views.editMembership.email')}},[_vm._v(" "+_vm._s(membership.email)+" ")])],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":_vm.$t('views.editMembership.rol'),"id":"membership_type-select","message":form.errors.role_type_id,"type":form.types.role_type_id,"label-for":"membership_type-select-input","data-testid":"membership_type-select-input"}},[_c('role-type-selector',{attrs:{"id":"membership_type-select-input"},model:{value:(form.values.role_type_id),callback:function ($$v) {_vm.$set(form.values, "role_type_id", $$v)},expression:"form.values.role_type_id"}})],1)],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{attrs:{"label":division.is_project
                            ? _vm.$t('views.editMembership.project')
                            : _vm.$t('views.editMembership.division')}},[_vm._v(" "+_vm._s(division.name)+" ")])],1)]),_c('div',{staticClass:"buttons is-right"},[_c('b-button',{staticClass:"is-azul-imfd-invert",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('views.editMembership.cancel'))+" ")]),_c('b-button',{staticClass:"is-azul-imfd",attrs:{"icon-left":"pencil","native-type":"submit","disabled":!form.errors}},[_vm._v(" "+_vm._s(_vm.$t('views.editMembership.submit'))+" ")])],1)])]}}],null,true)})],1)]),_c('div',{staticClass:"column is-5"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('b-icon',{attrs:{"icon":"information-outline","size":"is-large","type":"is-azul-imfd"}})],1),_c('div',{staticClass:"media-content"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('views.editMembership.warningLabel'))+" "),_c('strong',[_vm._v(_vm._s(division.name))])])])])])])])])]}},{key:"rejected",fn:function(error){return [_c('v-fail',{attrs:{"error":error}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }